import React from "react";
import data from "./Data.js"
import Blog from "./components/Blog";

const BlogSection = () => {
    return (
        <section className="blog pt-5"  >
           
            
                {data.blogDataBucatar.map((item, index) => {
                    return (
                        <Blog key={index} id={item.id} img={item.img} title1={item.title1} title2={item.title2} message={item.message}  />
                    )
                })}


           
        </section>


    )


}

export default BlogSection;