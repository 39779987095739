import React from "react";
import data from "./Data.js"
import Blog from "./components/Blog";

import music from "./images/music.png"

import muz from "./video/muz3.mp3"



const BlogSection = () => {
    return (
        <section id="blog" className="blog pt-4"  >
            <div className="text-center">
<span className="text-center">
    <img className="music animate__animated animate__pulse animate__delay-4s animate__infinite" src={music}/>
    <audio src={muz} controls autoPlay loop ></audio>
</span>
           </div>
            
                {data.blogDataNoi.map((item, index) => {
                    return (
                        <Blog key={index} id={item.id} img={item.img} title1={item.title1} title2={item.title2} message={item.message}  />
                    )
                })}


           
        </section>


    )


}

export default BlogSection;