import restaurant from "./images/ruiloba.jpg";
import img_card from "./images/n.jpeg";
import img_nasi from "./images/nasi.jpeg";
import img_bucatar from "./images/bucatar.jpeg";
import img_music from "./images/formatia.jpeg";
import imgOmSuflet from "./images/14.jpg";
//import imgOmSufletmb from "./images/14.jpg";
import imgheader from "./images/img6.jpg";
import imgheadermiini from "./images/img4.jpg";
import imgheadermiinimb from "./images/img4.jpg";
import imgconfirmare from "./images/11.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        mire: "Vasile",
        mireasa: "Irina",
        title: "Invitatie Online",
        data: "16 Septembrie 2022",
        data_confirmare: "01.09.2022",
        savedata: "Vă invităm!!!",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "bazili1980@gmail.com", 
       tel: "+34 642 64 78 36",
       phone: "tel:+34642647836",
       viber: "viber://chat?number=%2B34642647836",
       whatsapp: "https://wa.me/+34642647836",
       messenger: "https://www.messenger.com/t/ctsw.tansportes",
       tel1: "+37368355031",
       phone1: "tel:+XXX",
       viber1: "viber://chat?number=%2BXX",
       whatsapp1: "https://wa.me/+XXX",
       messenger1: "https://www.messenger.com/t/100008466997487",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Ruiloba",
            data: "16 septembrie 2022, vineri, ora 19:30",
            adress: " C. Lopez Seña 26, 39770 Laredo Cantabria",
            harta: "https://goo.gl/maps/1gStwNPACezLSPCE6",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2898.361948601433!2d-3.417862199999999!3d43.4112639!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4eea9e4d058387%3A0xa9f9d4dbf24413a7!2sXxxx!5e0!3m2!1sro!2s!4v1659104229331!5m2!1sro!2s"
           
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Invitație!",
            title2: "VĂ INVITĂM LA CEL MAI IMPORTANT EVENIMENT DIN VIAȚA NOASTRĂ!",
            message: "În ziua în care vom celebra căsătoria nostră, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img_nasi,
            title1: "Nașii",
            title2: "Dorin & Victoria",
            message: "Nașii sunt unicii părinți  pe care îi putem alege. Și noi v-am ales pe Voi! Cei mai buni!",
        }
    ],
    blogDataBucatar: [
        {
            id: 2,
            img: img_bucatar,
            title1: "Vă invităm alături de cea mai bună bucătăreasă:",
            title2: "Natalia Căpătici Vizitiu",
            message: "Și să gustăm din bucatele delicioase a mânuților de aur!",
        }
    ],
    blogDataMusic: [
        {
            id: 2,
            img: img_music,
            title1: "Sub conducerea muzicală de duetul:",
            title2: "Verde Înrourat",
            message: "Distracția și dansurile sunt asigurate până dimineață!",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "\"Căsnicia este o artă ce trebuie să o creezi în fiecare zi\"",
            message: " – R. Tagore",
        }
    ],
    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ], 

}

export default data;