
import React, { Component } from "react";




export default class Blog extends Component {
  render() {
    return (

      <div className="container blog-container mt-4">
        <div className="row main-row ">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="blog-img text-center">
              <img className="img-fluid" src={this.props.img} />
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="blog-title mt-lg-5 mt-md-2 mt-sm-1">
            
            <h5 className="text-center" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                {this.props.title1}
              </h5>
              <h2 className="text-center p-2">
                {this.props.title2}
              </h2>
              <h4 className="text-center pb-5">
                {this.props.message}
              </h4>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

